import React from "react";

import "./terms-policy.css";
import Footer from "../footer/Footer";

function TermsMandarin() {
  return (
    <div className="policy">
      <main>
        <h1>條款及細則</h1>
        <p>
          以下是由 Vera Technology Company
          Limited（下稱「我們」或「本公司」）開發的 Vera
          言語治療應用程式（下稱「本應用程式」）的使用條款和細則。「服務」是指透過本應用程式提供的任何和/或所有服務。
        </p>
        <ol>
          <li>
            <b>同意和家長責任</b>
            <ol type="a">
              <li>
                閣下下載或使用本應用程式，即表明閣下已閱讀、瞭解並同意受使用條款和細則所約束。我們有權於任何時間修訂或更改使用條款和細則。
              </li>
              <li>
                如閣下代表未成年人士使用本應用程式，即代表閣下確認閣下是該未成年人士的父母或法定監護人，有權代表他們同意本協議。透過下載或使用本應用程式，閣下已同意受使用條款和細則所約束，並同意我們可以隨時更改有關條款及細則。
              </li>
            </ol>
          </li>
          <li>
            <b>免責聲明</b>
            <ol type="a">
              <li>
                本應用程式是一個初步版本，仍在開發過程中，並不是最終產品，僅供測試和評估之用。
              </li>
              <li>
                本應用程式無意用作診斷及/或治療醫療或健康狀況，無意取代專業醫療或治療建議。我們不能替代任何合資格的專業醫療或治療意見。閣下應就任何有關醫療或健康狀況的問題諮詢言語治療師、合資格醫療專業人員或醫療服務提供者的意見。切勿因為本應用程式提供的任何結論、建議、內容或其他資訊而忽視專業醫療建議及/或延遲尋求專業醫療建議。
              </li>
              <li>
                本應用程式中提供的內容僅用於提供資訊和教育用途，並沒有診斷、治療、治癒或預防任何言語或語言障礙的目的。我們絕不保證或表示本應用程式能夠診斷、治療、治癒或預防任何言語或語言障礙，也不保證或表示本應用程式提供的任何結論、建議、內容或其他資訊是準確、最新、可靠、完整或適合任何醫療目的。閣下一旦下載和使用本應用程式，即代表閣下已同意我們從來沒有作出此類保證。閣下需要自行承擔依賴本應用程式提供的結論、建議、內容或其他資訊的風險。
              </li>
              <li>
                閣下一旦下載和使用本應用程式，即表示閣下已確認並同意(i)
                本應用程式不能取代專業的言語治療、醫療或合資格醫療服務提供者提供的建議；(ii)
                閣下已閱讀、瞭解並同意本免責聲明的全部內容。
              </li>
            </ol>
          </li>
          <li>
            <b>知識產權</b>
            <ol type="a">
              <li>
                閣下不得以任何方式複製或修改本應用程式、本應用程式的任何部分或與本應用程式相關的商標、版權、專利或知識產權。
              </li>
              <li>
                閣下不得析取或試圖析取本應用程式的編碼、文檔或程式之內容，也不得試圖將本應用程式翻譯成其他語言或製作衍生版本。本應用程式及與其相關的所有商標、版權、資料庫權和其他知識產權均屬Vera
                Technology Company Limited所擁有 。
              </li>
            </ol>
          </li>
          <li>
            <b>保安政策</b>
            <ol type="a">
              <li>
                本應用程式將儲存並處理閣下向我們提供的個人數據，以便我們提供服務。
                閣下有責任確保手機和本應用程式存取的安全。因此，我們建議閣下不要對手機進行越獄或獲取Root（指解除軟件及裝置製造商的限制），它可能會使閣下的手機受到惡意軟件、病毒或惡意程式的攻擊，降低手機的安全性，並可能會令本應用程式無法正常運作。
              </li>
              <li>
                一旦下載或使用本應用程式，即表示閣下同意我們僅在合理及商業可行的情況下確保本應用程式收集的資料儲存和傳輸的安全性。
              </li>
            </ol>
          </li>
          <li>
            <b>第三方連結和服務</b>
            <ol type="a">
              <li>
                本應用程式會連結至第三方服務，並受到其條款和細則政策的約束。
                本應用程式的第三方服務提供者的條款和細則的連結 <br />
                <a href="https://policies.google.com/terms" target="_blank">
                  Google Play Services
                </a>
                <br></br>
                <a
                  href="https://www.apple.com/legal/internet-services/itunes/"
                  target="_blank"
                >
                  Apple App Store
                </a>
              </li>
              <li>
                除本應用程式的隱私政策中所述的細則外，我們對任何第三方收集、使用或以其他方式處理資料不承擔任何責任。
              </li>
            </ol>
          </li>
          <li>
            <b>網路連接</b>
            <ol type="a">
              <li>
                本應用程式的某些功能需要有效的網路連線。連接可透過 Wi-Fi
                或由閣下的行動網路供應商提供，但如閣下無法連接
                Wi-Fi，亦沒有任何網路連接或網路數據，Vera Technology Company
                Limited 對應用程式無法正常運作不承擔任何責任。如閣下在沒有
                Wi-Fi提供的區域使用本應用程式，閣下與網路供應商達成的協議條款仍然適用。因此，閣下的網路供應商可能會向閣下收取使用本應用程式期間的數據使用費用或其他第三方費用。在使用本應用程式時，閣下將承擔任何此類收費的所有責任，包括但不限於以下情況——閣下在居住地（即地區或國家）之外使用本應用程式時未關閉數據漫遊的情況下的漫遊數據費用。如閣下不是使用本應用程式裝置的帳單付款人，則代表閣下在下載或使用本應用程式時向我們聲明閣下已從帳單付款人獲得使用本應用程式的授權。
              </li>
            </ol>
          </li>
          <li>
            <b>責任限制</b>
            <ol type="a">
              <li>
                Vera Technology Company Limited
                不能對閣下使用本應用程式的方式承擔任何責任，即包括但不限於以下情況——閣下需要確保閣下的設備保持充電狀態，如因為電量耗盡而無法打開設備以使用服務，本公司無需承擔任何法律責任。
              </li>
              <li>
                我們會盡力確保本應用程式所提供的信息為正確和最新，但我們依賴第三方向我們提供信息，以便我們提供服務。對於閣下因完全依賴應用程式的功能而遭受的任何直接或間接損失，本公司無需承擔任何法律責任。
              </li>
              <li>
                本公司在任何情況下均不會對閣下或任何由第三方因使用本應用程式或與使用本應用程式有關而可能而引致的任何形式的損失或損害承擔責任。閣下須自行承擔依賴本應用程式提供的任何結論、建議、內容或其他資訊的風險。閣下須對其行為以及因使用本應用程式而可能產生的任何後果承擔全部責任。
              </li>
            </ol>
          </li>
          <li>
            <b>賠償</b>
            <ol type="a">
              <li>
                閣下無條件同意，若本公司或與本公司相關之人（包括但不限於其主管、董事、股東及/或員工）因下列事項而遭受任何索賠、訴訟、要求、責任及/或和解，或被指稱因下列事項而遭受任何索賠、訴訟、要求、責任及/或和解，閣下同意賠償本公司及與本公司相關之人，並使其免受損害：(i)
                使用本應用程式；(ii) 依賴本應用程式提供之任何結論、建議、內容
                或其他資訊；或(iii)
                使用本應用程式提供任何結論、建議、內容或其他信息，包括但不限於在任何社交媒體平台張貼相同的內容。
              </li>
            </ol>
          </li>
          <li>
            <b>修訂與更新</b>
            <ol type="a">
              <li>
                Vera Technology Company Limited
                致力於確保本應用程式的運作和效率。因此，我們保留隨時以任何理由對應用程式進行更改或對其服務收費的權利。在未事先通知並徵得閣下同意，我們絕不會向閣下收取本應用程式或其服務的費用。
              </li>
              <li>
                我們的服務不斷地發展，我們或會需要更新本應用程式。本應用程式目前可在
                Android 和 iOS
                上使用。這些系統（及因擴展本應用程式而採用的任何其他系統）的要求可能會隨時更改，如閣下需要繼續使用我們的服務，閣下將需要下載及更新本應用程式的版本。
                本公司不承諾提供更新本應用程式，使本應用程式的版本與閣下相關和/或與閣下設備上安裝的
                Android/iOS
                版本相容。但是，透過使用本應用程式，閣下將承諾接受應用程式的更新。我們有權停止提供本應用程式的服務，並可以隨時終止本應用程式的運作，而無需向閣下發出終止通知。除非我們作出通知，否則在終止時，(a)
                本條款中授予閣下的權利和許可將立即終止；(b)
                閣下必須停止使用本應用程式，並需根據我們的要求，立即將本應用程式從閣下的裝置中刪除。
              </li>
            </ol>
          </li>
          <li>
            <b>條款及細則修訂</b>
            <ol type="a">
              <li>
                我們會不時更新我們的條款及細則。我們建議閣下定期查閱本頁以檢閱作出的修訂。
                我們會在對此頁面上發布更新條款和細則時向閣下發出通知。
              </li>
              <li>本條款及細則自 2023 年 9 月 22 日起生效。</li>
            </ol>
          </li>
          <li>
            <b>隱私政策</b>
            <ol type="a">
              <li>
                閣下一旦下載或使用本應用程式，即表示閣下同意我們不時更新{" "}
                <a
                  href="https://www.veraspeechapp.hk/#/policy-zh"
                  target="_blank"
                >
                  隱私政策條款
                </a>
                。
              </li>
            </ol>
          </li>
          <li>
            <b>聯絡我們</b>
            <ol type="a">
              <li>
                如果閣下對我們的條款及細則有任何疑問或建議，請透過{" "}
                <a href="mailto:admin@veraspeechapp.hk">
                  admin@veraspeechapp.hk
                </a>
                與我們聯繫。
              </li>
            </ol>
          </li>
          <li>
            <b>英文本及中文譯本</b>
            <ol type="a">
              <li>
                本條款及細則以英文訂立，並附有中文譯本。如果本條款及細則的中、英文本有任何不符或衝突，應以英文本為準。
              </li>
            </ol>
          </li>
        </ol>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default TermsMandarin;
