import React from "react";

import "./terms-policy.css";
import Footer from "../footer/Footer";

function PolicyChinese() {
  return (
    <div className="policy">
      <main>
        <h1>隱私政策</h1>
        <p>
          Vera Technology Company Limited （下稱「我們」）建立了 Vera
          言語治療應用程式（下稱「本應用程式」）。服務由 Vera Technology Company
          Limited 免費提供使用，並由 Vera Technology Company Limited
          不定時更新。「服務」是指透過本應用程式提供的任何和/或所有服務。
          如果任何人決定使用我們的服務，此頁面用於告知訪問者有關我們收集、使用和揭露個人資料的政策。「個人資料」是指使用者提供的任何個人識別的資料，包括但不限於語音樣本。
          如果閣下選擇使用我們的服務，即表示閣下同意不定時更新的隱私權政策的所有條款。
          我們收集的個人資料用於提供和改善服務。除本隱私政策中所述的情況外，我們不會使用或與任何人分享閣下的資料，而本隱私政策會不定時更新。
          除非本隱私政策中另有定義，否則本隱私政策中使用的術語與我們的{" "}
          <a href="https://www.veraspeechapp.hk/#/terms-zh" target="_blank">
            條款與細則
          </a>
          具有相同的含義。這些條款與細則可透過 Vera 言語治療應用程式存取。
        </p>
        <ol>
          <li>
            <b>資料收集和使用</b>
            <ol type="a">
              <li>
                為了在使用我們的服務時獲得更好的體驗，我們可能會要求閣下向我們提供某些個人資料。
                這些資料將按照本隱私政策中的規定使用。
                本應用程式使用第三方服務，這些服務可能會收集識別閣下身份的資料。除本應用程式的隱私政策中所述的細則外，我們對任何第三方收集、使用或以其他方式處理資料不承擔任何責任。本應用程式的第三方服務提供者的條款和細則的連結
                <br />
                <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                >
                  Google Play Services
                </a>
              </li>
            </ol>
          </li>
          <li>
            <b>記錄數據</b>
            <ol type="a">
              <li>
                閣下一旦使用我們的服務，即表示同意每當使用我們的服務，我們可能會（透過第三方產品）在閣下的手機上收集資料和資訊（稱為日誌數據）。
                此日誌數據包括但不限於閣下的裝置網際網路協定（「IP」）位址、裝置名稱、作業系統版本、使用我們的服務時的應用程式設定、使用我們的服務的時間和日期等資料、及其他數據資料。
              </li>
            </ol>
          </li>
          <li>
            <b>Cookies</b>
            <ol type="a">
              <li>
                “cookie”是存取少量資料的文件，通常用作匿名獨特識別碼。這些資料將從閣下造訪的網站發送到瀏覽器，並儲存在裝置的記憶體中。
                本應用程式不會直接地使用
                “cookie”。但是，本應用程式可能會使用“cookie”的第三方程式碼和程式庫，而他們可能使用
                “cookie”來收集資料並改善其服務。閣下可以選擇接受或拒絕這些“cookie”，並了解“cookie”何時被傳送到閣下的裝置。如果閣下選擇拒絕我們的“cookie”，閣下可能無法使用本應用程式某些服務。
              </li>
            </ol>
          </li>
          <li>
            <b>服務供應商</b>
            <ol type="a">
              <li>
                我們因以下原因僱用第三方供應商：
                <ol>
                  <li>為我們的服務提供便利;</li>
                  <li>代表我們提供服務;</li>
                  <li>執行與我們的服務相關的服務; 或</li>
                  <li>協助我們分析服務的使用情況。</li>
                </ol>
              </li>
              <li>
                閣下一旦使用我們的服務，即表示閣下同意這些第三方供應商僅為我們指定的目的存取其個人資料。但是，他們不被容許為其他目的披露或使用這些資料。
              </li>
            </ol>
          </li>
          <li>
            <b>安全</b>
            <ol type="a">
              <li>
                我們採取合理的安全措施，以保護從本應用程式所收集之個人資料和語音樣本。如果閣下使用我們的服務，即表示閣下同意Vera
                Technology Company
                Limited以措施的合理性及商業可行性為大前題，僅負責確保透過本應用程式所收集資料儲存與傳輸的安全。如果閣下使用我們的服務，即表示閣下同意我們不會對透過本應用程式收集的資料的儲存或傳輸安全性承擔全部的責任。
              </li>
            </ol>
          </li>
          <li>
            <b>其他網站的連結</b>
            <ol type="a">
              <li>
                本服務可能包含用來訪問其他網站的連結。如果閣下點擊第三方連結，閣下將被轉移到該網站。請注意，這些第三方網站不由我們經營。因此，我們強烈建議閣下查看這些網站的隱私政策。如果閣下使用我們的服務，即表示閣下同意我們對任何第三方網站或服務的內容、隱私政策、資料安全或處理方法（包括但不限於有關任何使用者資料的處理方法）不承擔任何法律責任。
              </li>
            </ol>
          </li>
          <li>
            <b>本隱私政策的變更</b>
            <ol type="a">
              <li>
                我們會不時更新我們的隱私政策。我們建議閣下定期查閱本頁以檢閱作出的修訂。我們會在此頁面上發布更新的條款和細則。
                本隱私政策自 2023 年 9 月 22 日起生效。
              </li>
            </ol>
          </li>
          <li>
            <b>聯絡我們</b>
            <ol type="a">
              <li>
                如果閣下對我們的隱私政策有任何疑問或建議，請隨時透過{" "}
                <a href="mailto:admin@veraspeechapp.hk">admin@veraspeechapp.hk</a>
                與我們聯繫。
              </li>
            </ol>
          </li>
          <li>
            <b>英文本及中文譯本</b>
            <ol type="a">
              <li>
                本隱私政策以英文訂立，並附有中文譯本。如果本隱私政策的中、英文本有任何不符或衝突，應以英文本為準。
              </li>
            </ol>
          </li>
        </ol>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default PolicyChinese;
