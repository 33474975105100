import React from 'react';
import '../../App.css';
import Info from '../texta';
import Footer from '../footer/Footer';

function Home() {
  return (
    <div className='bgwhite'>
      <Info />
     <Footer/>
    </div>
  );
}

export default Home;
