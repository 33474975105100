import React from 'react';
import '../../App.css';
import Cards from '../team/Cards'; 
import Footer from '../footer/Footer';


function Home() {
  return (
    <>
      <Cards />
      <Footer/>
     </>
  );
}

export default Home;
